<template>
  <div class="w-full">
    <div>
      <p class="text-sm font-semibold">
        <n-html :text="$t('form.contract.edit.picture.device_label')" />
      </p>
      <div
        class="relative border border-dashed border-brand-purple-500 bg-brand-purple-100/25 cursor-pointer"
      >
        <p
          class="hidden lg:block absolute pl-6 top-1/2 -translate-y-1/2 left-0 right-1/3 text-left align-middle text-brand-purple-500"
        >
          <n-html :text="$t('form.report_theft_statement.placeholder')" />
        </p>
        <n-button-regular
          @click.native="close"
          class="absolute left-0 lg:left-auto lg:right-0 top-1/2 -translate-y-1/2 mx-6 text-brand-purple-500"
        >
          <n-html :text="$t('form.report_theft_statement.button')" />
        </n-button-regular>
        <input
          type="file"
          class="opacity-0 h-20 w-full cursor-pointer"
          multiple
          @change="e => onChange(e, 'device')"
          accept="image/*"
        />
      </div>
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-y-2 gap-x-6 py-2">
        <div
          class="flex justify-between bg-brand-gray-100 text-xs text-brand-gray-400 px-3 py-1"
          v-for="file in pictures.device_pictures"
          :key="file.name"
        >
          {{ file.name }}
          <n-icon
            xs
            name="Delete"
            class="cursor-pointer"
            @click.native="removeFile(index, 'device')"
          />
        </div>
      </div>
    </div>
    <div>
      <p class="text-sm font-semibold">
        <n-html :text="$t('form.contract.edit.picture.receipt_label')" />
      </p>
      <div
        class="relative border border-dashed border-brand-purple-500 bg-brand-purple-100/25 cursor-pointer"
      >
        <p
          class="hidden lg:block absolute pl-6 top-1/2 -translate-y-1/2 left-0 right-1/3 text-left align-middle text-brand-purple-500"
        >
          <n-html :text="$t('form.report_theft_statement.placeholder')" />
        </p>
        <n-button-regular
          @click.native="close"
          class="absolute left-0 lg:left-auto lg:right-0 top-1/2 -translate-y-1/2 mx-6 text-brand-purple-500"
        >
          <n-html :text="$t('form.report_theft_statement.button')" />
        </n-button-regular>
        <input
          type="file"
          class="opacity-0 h-20 w-full cursor-pointer"
          multiple
          @change="e => onChange(e, 'receipt')"
          accept="image/*"
        />
      </div>
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-y-2 gap-x-6 py-2">
        <div
          class="flex justify-between bg-brand-gray-100 text-xs text-brand-gray-400 px-3 py-1"
          v-for="file in pictures.receipt_pictures"
          :key="file.name"
        >
          {{ file.name }}
          <n-icon
            xs
            name="Delete"
            class="cursor-pointer"
            @click.native="removeFile(index, 'receipt')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import format from "../../mixins/format";

export default {
  name: "components.insurances.insurance-image-upload",
  mixin: [format],
  props: {},
  computed: {},
  data: () => ({
    uploaded_files: [],
    files: [],
    pictures: {
      device_pictures: [],
      receipt_pictures: []
    }
  }),
  methods: {
    onChange(e, fileType) {
      let files = e.target.files || e.dataTransfer.files;
      if (files.length) {
        this.uploaded_files = files;
      }
      this.uploaded_files.forEach(thisFile => {
        if (fileType === "device") {
          this.pictures.device_pictures.push({
            name: thisFile.name,
            file: thisFile
          });
        } else if (fileType === "receipt") {
          this.pictures.receipt_pictures.push({
            name: thisFile.name,
            file: thisFile
          });
        }
        // this.files.push({ name: thisFile.name, file: thisFile });
      });
      this.uploaded_files = [];
    },
    removeFile(i, fileType) {
      if (fileType === "device") {
        this.pictures.device_pictures.splice(i, 1);
      } else if (fileType === "receipt") {
        this.pictures.receipt_pictures.splice(i, 1);
      }
    }
  },
  watch: {
    pictures: {
      handler(n) {
        console.log(n);
        this.$emit("imageSelected", this.pictures);
      },
      deep: true
    }
  }
};
</script>
